[data-content-disabled="true"] {
  mask-image: linear-gradient(to bottom, rgba(var(--bg-color-rgb), 1), rgba(var(--bg-color-rgb), 0) 80%);
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  width: 100%;
}

.public__upgrade-banner {
  bottom: var(--spacing-12);
  max-width: var(--screen-xl);
  position: absolute;
  z-index: 100;
}

.doc__upgrade-banner {
  left: calc(var(--sidebar-width) + var(--spacing-12));
  right: var(--spacing-12);
  max-width: calc(100% - var(--sidebar-width) - var(--spacing-12) * 2);
}

.hub__upgrade-banner {
  left: var(--spacing-8);
  right: var(--spacing-8);
  max-width: calc(100% - var(--spacing-8)*2);
}