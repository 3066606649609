.menu-link {
  @apply cursor-pointer dark:hover:text-white;

  &:hover {
    > svg {
      @apply duration-200 transform rotate-180 transition;
    }

    .menu-dropdown {
      @apply min-w-80 opacity-100 visible;
      top: 56px;
    }
  }
}

.menu-dropdown {
  @apply absolute bg-white rounded-lg p-4 shadow-medium opacity-0;
  transform: translateX(-50%);
  left: 50%;
  width: max-content;
  visibility: hidden;
  top: 64px;
  transition: opacity 0.2s ease, top 0.2s ease;

  .menu-element {
    @apply flex items-center p-4 rounded-lg transition duration-200;
    justify-content: start;

    .menu-description {
      @apply flex flex-col ml-4;

      .menu-description-title {
        @apply flex items-center text-black font-sans font-semibold;
      }

      .menu-description-text {
        @apply text-lightblack;
      }
    }

    &:hover {
      @apply bg-lightblue;
    }
  }
}

.mobile-dropdown {
  @apply h-0 overflow-hidden;
  transition: height 0.4s ease;
}
