table {
  border-collapse: separate;
  border-spacing: 0;
  font-size: 0.875rem;
  text-align: left;
  width: 100%;
  word-break: keep-all;
}

thead {
  line-height: var(--title-line-height);
  vertical-align: top;
}

tbody {
  vertical-align: top;
}

th {
  background-color: var(--bg-color-light);
  color: var(--text-color-secondary);
  font-size: var(--text-xs);
  font-weight: var(--font-weight-medium);
  padding: var(--spacing-3) var(--spacing-4);

  &.sortable {
    a {
      align-items: center;
      display: flex;
      gap: var(--spacing-2);
    }

    svg {
      width: 7px;

      &:is(.sort-asc *, .sort-desc *) {
        color: var(--accent-color);
      }
    }
  }
}

/* This vertically aligns th and td when they're put on the same row */
th:is(tr:has(th + td) *) {
  padding-block-start: calc(var(--spacing-4) + var(--spacing-05));
}

th:first-child {
  border-top-left-radius: var(--border-radius-large);
  border-bottom-left-radius: var(--border-radius-large);
}

th:last-child {
  border-top-right-radius: var(--border-radius-large);
  border-bottom-right-radius: var(--border-radius-large);
}

tr {
  &[data-action] {
    cursor: pointer;

    &:hover {
      background-color: var(--panel-bg-color);
    }
  }
}

td {
  border-bottom: var(--border-default);
  word-break: break-word;
  padding: var(--spacing-4);
  vertical-align: middle;


  [data-button-style="naked"] {
    height: auto;

    svg {
      height: auto;
    }
  }

  [data-link-style="blue-link"] {
    text-decoration: none;
    color: var(--doc-base-color);
  }

  .immutable {
    align-items: center;
    background-color: var(--panel-bg-color);
    border: var(--border-default);
    border-radius: var(--border-radius-medium);
    display: flex;
    height: var(--spacing-8);
    padding-inline: var(--spacing-4);
  }
}

td ul {
  margin-top: 0;
}

thead {
  line-height: var(--title-line-height);
  vertical-align: top;

  tr {
    td {
      border-top: var(--border-default);
    }

    td:first-child {
      border-bottom-left-radius: 0;
      border-top-left-radius: var(--border-radius-large);
    }

    td:last-child {
      border-bottom-right-radius: 0;
      border-top-right-radius: var(--border-radius-large);
    }
  }
}

table.authentication{
  th {
    border-inline: var(--border-default);
  }

  td {
    border-right: var(--border-default);
    width: 80%;
  }

  th,
  td {
    border-radius: 0;
    border-top: 0;
  }

  tr:first-child th {
    border-top: var(--border-default);
    border-top-left-radius: var(--border-radius-large);
  }

  tr:last-child th {
    border-bottom-left-radius: var(--border-radius-large);
  }

  tr:first-child td {
    border-top: var(--border-default);
    border-top-right-radius: var(--border-radius-large);
  }

  tr:last-child td {
    border-bottom-right-radius: var(--border-radius-large);
  }
}
