/* 
FLOW COMPOSITION 
Like the Every Layout stack: https://every-layout.dev/layouts/stack/
Info about this implementation: https://piccalil.li/blog/my-favourite-3-lines-of-css/ 
*/

/* Made it apply only if the preceding element is visible to not get a weird
   margin on top of first visible element */
.flow > *:not([hidden], [type=hidden]) + * {
  margin-block-start: var(--flow-space, 1em);
}
