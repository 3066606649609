.upgrade-subscription-modal {
  header {
    text-transform: none;
  }

  .dialog-body {
    font-weight :500;
    text-transform: none;

    button {
      width: fit-content !important;
      align-self: end;
    }
  }
}

button[data-dialog-id-param="upgrade-subscription-modal"] {
  font-weight: var(--font-weight-semibold);
}