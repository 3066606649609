.code-block {
  color: var(--code-color);
  overflow: auto;
  white-space: pre;
  font-size: 0.825rem;

  .title {
    padding: var(--spacing-2) var(--spacing-3);
  }
}

.code-block.verb {
  text-transform: uppercase;
  overflow: initial;
}

.code-inline {
  background-color: var(--panel-bg-color);
  border: var(--border-default);
  border-radius: var(--border-radius-medium);
  font-size: 0.75rem;
  hyphens: none;
  padding: var(--spacing-05);
}

.code-block.dark {
  background-color: var(--gray-90);
  border-radius: var(--border-radius-large);
  font-size: 0.875rem;
  white-space: normal;

  .code-block__title {
    background-color: var(--bg-code-title);
    color: var(--white);
    line-height: 1rem;
    padding: var(--spacing-4) var(--spacing-3);
  }

  .code-block__content {
    column-gap: var(--spacing-1);
    display: grid;
    position: relative;

    .highlight {
      font-size: 0.875rem;
      margin: 0;
      width: 100%;

      .err {
        background: transparent;
      }
    }
  }

  .code-block__actions {
    align-items: center;
    color: var(--white);
    display: flex;
    position: absolute;
    right: var(--spacing-4);
    top: var(--spacing-5);

    .copy-icon {
      margin-left: var(--spacing-3);
    }

    button {
      --button-text-color: var(--white);
    }
  }
}
