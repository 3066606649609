.link {
  @apply inline-flex items-center h-full transition duration-200;

  span {
    @apply relative;

    &::after {
      content: "";
      @apply absolute left-0 right-0 h-px opacity-0 transition duration-200;
      bottom: -2px;
    }
  }

  .link-black {
    span:first-of-type {
      @apply dark:text-gray-40;

      &::after {
        @apply bg-black dark:bg-white;
      }
    }

    &:hover {
      span:first-of-type {
        @apply text-black dark:text-white;
      }
    }
  }

  .link-blue {
    span:first-of-type {
      &::after {
        @apply bg-blue dark:bg-blue-40;
      }
    }
  }

  &:hover {
    span:first-of-type {
      &::after {
        @apply opacity-100;
      }
    }
  }
}

.link-arrow {
  @apply inline-flex text-blue font-semibold items-center;

  span {
    @apply mr-2;
  }

  img, svg {
    /* purgecss ignore current */
    transition: transform 0.2s ease;
  }

  &:hover {
    img, svg {
      /* purgecss ignore current */
      transform: translateX(8px);
    }
  }
}
