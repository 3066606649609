.btn {
  @apply relative h-12 justify-center inline-flex items-center px-8 py-3 rounded font-sans font-semibold cursor-pointer text-center whitespace-nowrap transition duration-200;

  .btn-sm {
    @apply h-8 px-4;
  }

  &:not(.btn-outline) {
    @apply shadow-base;
  }

  &:not(.btn-outline) {
    @apply shadow-base;
  }

  &.btn-outline {
    @apply bg-gradient-blue text-blue overflow-hidden;
    transition: all 0.3s ease;

    &::before {
      content: "";
      @apply absolute bg-gradient-blue w-full top-0;
      top: auto;
      left: 0;
      bottom: 0;
      height: 0;
      border-radius: 4px;
      z-index: 2;
      transition: height 0.3s ease;
    }

    span {
      @apply relative;
      z-index: 2;
    }

    &::after {
      content: "";
      @apply absolute inset-0 bg-white m-px;
      border-radius: 3px;
    }

    &:hover {
      @apply text-white shadow-base;

      &::before {
        top: 0;
        bottom: auto;
        height: 100%;
      }
    }
  }

  &.btn-sm {
    height: 2.25rem;
  }

  &.btn-black {
    @apply bg-black text-white;
  }

  &.btn-blue {
    @apply bg-gradient-blue text-white;
  }

  &.btn-white {
    @apply bg-white text-blue;
  }

  &.btn-github {
    @apply inline-block w-full bg-shark text-white;
  }

  svg {
    @apply absolute transform-center-y;
  }

  &:active {
    transform: translateY(1px);
  }

  &:hover {
    &:not(.btn-outline) {
      @apply opacity-90;
    }
  }
}
