.menu-mobile {
  @apply h-full;

  .menu-button {
    @apply items-center h-full relative visible z-10 bg-transparent m-0 border-none cursor-pointer px-8;
    transition: opacity 0.15s linear, filter 0.15s linear;
    color: inherit;
    text-transform: none;

    .menu-button-inner {
      transition-duration: 0.075s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::before {
        transition: top 0.075s 0.12s ease, opacity 0.075s ease;
      }

      &::after {
        transition: bottom 0.075s 0.12s ease,
          transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }
  }

  .menu-mobile-navigation {
    @apply fixed bg-white px-8 pb-8 left-0 w-full invisible opacity-0 flex flex-col -mt-2 dark:bg-blue-100;
    height: calc(100% - 64px);
    animation: drawDown 0.4s both;
  }

  &[open] {
    .menu-mobile-navigation {
      @apply opacity-100 visible;
    }

    .menu-button-inner {
      transform: rotate(45deg);
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-delay: 0.12s;

      &::before {
        top: 0;
        transition: top 0.075s ease, opacity 0.075s 0.12s ease;
        opacity: 0;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.075s ease,
          transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}

.menu-button-block {
  @apply inline-block relative w-6 h-full;
}

.menu-button-inner {
  @apply block rounded;
  top: 50%;
  transform: translateY(-50%);

  &,
  &::before,
  &::after {
    @apply absolute bg-black rounded w-6 dark:bg-white;
    height: 0.125rem;
    transition-property: transform;
    transition-duration: 0.2s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    top: 8px;
  }

  &::after {
    bottom: 8px;
  }
}

.menu-accordion {
  details {
    transition: height 0.4s ease;
    overflow: hidden;
  }

  .menu-accordion-mobile {
    details[open] {
      height: max-content;

      .mobile-dropdown {
        @apply block;
        height: max-content;
      }
    }
  }
}

@keyframes drawDown {
  0% {
    top: -100vh;
  }
  100% {
    top: 72px;
  }
}
