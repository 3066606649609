.button, :where(a.button, button, input[type="submit"], input[type="button"]) {
  --button-base-color: var(--doc-base-color, var(--base-color));
  --button-base-color-hover: color-mix(in srgb, var(--button-base-color), var(--gray-50) 35%);

  --button-color: var(--button-base-color);
  --button-color-hover: var(--button-base-color-hover);
  --button-text-color: var(--white);
  --button-text-color-hover: var(--white);
  --button-border-color: var(--button-color);
  --button-border-color-hover: var(--button-color-hover);

  align-items: center;
  background-color: var(--button-color);
  display: inline-flex;
  border: 1px solid var(--button-border-color);
  border-radius: var(--border-radius-medium);
  color: var(--button-text-color);
  cursor: pointer;
  font-family: var(--font-text);
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  gap: var(--spacing-2);
  height: var(--spacing-8);
  justify-content: center;
  line-height: 1;
  padding: var(--spacing-2) var(--spacing-4);
  text-align: center;
  text-decoration: none;
  transition: background-color var(--base-duration) var(--base-timing),
    border-color var(--base-duration) var(--base-timing);
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover {
    background: var(--button-color-hover);
    border-color: var(--button-border-color-hover);
    color: var(--button-text-color-hover);
    text-decoration: none;
  }

  &:focus-visible {
    outline: var(--focus-outline);
    outline-offset: var(--focus-outline-offset);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  svg {
    flex-shrink: 0;
    height: var(--spacing-4);
    width: var(--spacing-4);
  }

  /* --- button sizes --- */

  &[data-button-size="mini"] {
    font-size: 10px;
    gap: var(--spacing-1);
    height: var(--spacing-5);
    padding: var(--spacing-1) var(--spacing-1-5);
  }

  &[data-button-size="mini"] svg {
    height: var(--spacing-3);
    width: var(--spacing-3);
  }

  &[data-button-size="small"] {
    font-size: 0.75rem;
    gap: var(--spacing-1);
    height: var(--spacing-6);
    padding: var(--spacing-1) var(--spacing-3);
  }

  &[data-button-size="small"] svg {
    height: var(--spacing-3);
    width: var(--spacing-3);
  }

  &[data-button-size="large"] {
    height: var(--spacing-10);
    padding-inline: var(--spacing-5);
  }

  /* --- button styles --- */

  /* inverted button with base color outline */
  &[data-button-style="outline"] {
    --button-color: transparent;
    --button-color-hover: transparent;
    --button-border-color: color-mix(in oklch, var(--gray-40), transparent 50%);
    --button-border-color-hover: color-mix(in oklch, currentColor, transparent 50%);
    --button-text-color: currentColor;
    --button-text-color-hover: currentColor;
  }

  /* inverted button with neutral outline */
  &[data-button-style="secondary"] {
    --button-color: var(--bg-color);
    --button-color-hover: var(--bg-color);
    --button-border-color: var(--border-color-default);
    --button-text-color: var(--text-color);
    --button-text-color-hover: var(--text-color);
    --button-border-color-hover: var(--text-color);
  }

  /* White bg and gray font whatever darkmode */
  &[data-button-style="white"] {
    --button-color: var(--white);
    --button-color-hover: var(--white);
    --button-border-color: var(--gray-20);
    --button-text-color: var(--gray-70);
    --button-text-color-hover: var(--gray-70);
    --button-border-color-hover: var(--gray-70);
  }

  /* Darker blue bg and white font whatever darkmode */
  &[data-button-style="darker-blue"] {
    --button-color: var(--darker-blue);
    --button-color-hover: var(--darker-blue);
    /* --button-border-color: var(--gray-20); */
    --button-text-color: var(--white);
    --button-text-color-hover: var(--white);
    /* --button-border-color-hover: var(--gray-70); */
  }

  &[data-button-style="danger"] {
    --button-base-color: var(--red-60);
    --button-base-color-hover: var(--red-70);
  }

  &[data-button-style="action"] {
    --button-text-color: var(--text-color-inverted);
    --button-text-color-hover: var(--text-color-inverted);
    --button-color: var(--bg-color-inverted);
    --button-color-hover: var(--bg-color-inverted);
  }

  &[data-button-style="neutral"] {
    --button-text-color: var(--text-color);
    --button-text-color-hover: var(--text-color);
    --button-color: color-mix(in oklch, var(--bg-color-secondary), transparent 50%);
    --button-color-hover: color-mix(in oklch, var(--bg-color-secondary), transparent 20%);
    --button-border-color: transparent;
    --button-border-color-hover: transparent;
  }

  &:where([data-button-style="naked"]) {
    --button-color: transparent;
    --button-color-hover: transparent;
    --button-text-color: var(--button-base-color);
    --button-text-color-hover: var(--button-base-color-hover);
    --button-border: transparent;
    height: auto;
    padding: 0;

    &:hover {
      text-decoration: underline;
    }
  }

  &[data-button-style="link"] {
    --button-color: transparent;
    --button-color-hover: var(--button-color);
    --button-text-color: currentColor;
    --button-text-color-hover: var(--base-color);
    display: inline;
    height: auto;
    padding: 0;
    text-decoration: underline;
  }

  /* --- button padding --- */

  &[data-button-padding="large"] {
    padding-inline: var(--spacing-6);
  }

  /* --- button radius --- */

  &[data-button-radius="round"] {
    border-radius: var(--border-radius-round);
  }

  /* --- button states --- */

  &[data-button-state="success"] {
    --button-base-color: var(--green-60);
    --button-base-color-hover: var(--green-70);
  }

  &[data-button-state="error"] {
    --button-base-color: var(--red-60);
    --button-base-color-hover: var(--red-70);
  }

  &[data-button-state="warning"] {
    --button-base-color: var(--orange-60);
    --button-base-color-hover: var(--orange-70);
  }
}
