portal-header {
  background: var(--bg-color);
  border-bottom: var(--border-default);
  flex-shrink: 0;
  font-size: var(--text-sm);
  height: var(--spacing-16);
  z-index: 10;

  nav,
  [data-mobile-nav-target="drawer"] {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  nav {
    column-gap: var(--spacing-6);
  }

  .main-nav.text-title {
    column-gap: 0px;
  }

  [data-mobile-nav-target="drawer"] {
    container-type: inline-size;
    flex-grow: 1;
    gap: var(--spacing-3);
    justify-content: flex-end;
    z-index: 100;

    > a {
      word-break: normal;
    }
  }

  button {
    margin-left: auto;
  }

  page-breadcrumbs {
    font-size: var(--text-base);
    margin-inline-end: auto;
  }

  @media (max-width: 850px) {
    .dropdown {
      width: 100%;
    }

    [data-mobile-nav-target="drawer"] {
      align-items: stretch;
      gap: 0;
      justify-content: start;
      padding-inline: var(--spacing-6);

      .api-explorer-link {
        padding: 0;
      }
    }

    portal-resource-title .resource-name {
      display: block;
    }

    .dropdown-title {
      white-space: initial;
      padding: 0;
    }

    :is(.menu-dropdown) {
      --dropdown-shadow: color-mix(in srgb, var(--gray-50), transparent 90%);
      border: var(--border-default);
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
      left: 0;
      padding-inline: 0;
      top: initial;
      width: max-content;
      left: 0;
      width: 100%;

      a {
        border-radius: 0;
        box-sizing: border-box;
        white-space: initial;
        width: 100%;
      }
    }

    .doc-search {
      width: 100%;
    }
  }
}
