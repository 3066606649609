.callout {
  --callout-bg-color: var(--panel-bg-color);
  --callout-border-color: var(--gray-50);
  --callout-icon-path: url(../../images/icons/info.svg);

  @apply flex flex-col items-start;
  background-color: var(--callout-bg-color);
  border-left: 4px solid var(--callout-border-color);
  border-radius: var(--border-radius-medium);
  margin-bottom: var(--spacing-3);
  padding: var(--spacing-3);
  padding-left: var(--spacing-12);
  position: relative;
  row-gap: var(--spacing-3);
}

.callout::before {
  background: var(--callout-icon-path);
  content: "";
  display: inline-block;
  height: var(--spacing-4);
  left: var(--spacing-4);
  min-width: var(--spacing-4);
  position: absolute;
  top: calc(var(--spacing-4) + var(--spacing-05));
  width: var(--spacing-4);
}

.callout p {
  margin: 0;
}

.callout a {
  color: var(--base-color);
  text-decoration: none;
}

.callout-info {
  --callout-bg-color: var(--callout-background-info);
  --callout-border-color: var(--callout-border-color-info);
  --callout-icon-path: url(../../images/icons/info.svg);
}

.callout-success {
  --callout-bg-color: var(--callout-background-success);
  --callout-border-color: var(--callout-border-color-success);
  --callout-icon-path: url(../../images/icons/success.svg);
}

.callout-warn {
  --callout-bg-color: var(--callout-background-warn);
  --callout-border-color: var(--callout-border-color-warn);
  --callout-icon-path: url(../../images/icons/warning.svg);
}

.callout-error {
  --callout-bg-color: var(--callout-background-error);
  --callout-border-color: var(--callout-border-color-error);
  --callout-icon-path: url(../../images/icons/error.svg);
}
